<template>
  <div class="helloUser js-helloUser">
    <div class="helloUser__decor helloUser__decor--1"></div>
    <div class="helloUser__decor helloUser__decor--2"></div>
    <div class="helloUser__inner">
      <span class="helloUser__inner-title">Hello</span>
      <span class="helloUser__inner-name">{{$store.state.auth.user.firstName}}</span>
    </div>
  </div>
</template>

<script>
  import './HelloUser.styl'

  export default {
    name: 'HelloUser',
  }
</script>
