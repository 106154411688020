<template>
  <div
    class="page page--main"
    :class="[
      {'js-scrollToMap' : $store.state.auth.login},
      {'messageCenterOpen' : this.$store.state.messageCenter.status}
      ]"
  >
    <mainBlock/>
    <HelloUser/>
  </div>
</template>

<script>
  import mainBlock from '@/views/main/mainBlock/mainBlock'
  import HelloUser from '@/views/main/HelloUser/HelloUser';

  export default {
    name: 'LoginLayout',
    components: {
      mainBlock,
      HelloUser,
    },
    // mounted () {
    //   const animation = document.querySelector('.mainBlock__slogan').animate([
    //     { transform: 'translate3D(0, 400px, 0)' }
    //   ], 1000)
    //
    //   animation.addEventListener('finish', () => {
    //     document.querySelector('.mainBlock__slogan').style.transform = 'translate3D(0, 400px, 0)'
    //
    //     const animation2 = document.querySelector('.mainBlock__slogan').animate([
    //       { transform: 'translate3D(0, 200px, 0)' }
    //     ], 1000)
    //
    //     animation2.addEventListener('finish', () => {
    //       document.querySelector('.mainBlock__slogan').style.transform = 'translate3D(0, 200px, 0)'
    //     })
    //   })
    // }
  }
</script>
